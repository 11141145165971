import {
  GetManyProjectsQueryPrivate,
  PermissionAction,
  PermissionResource,
  ProjectResponsePrivate,
} from '@tests/types';
import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { inc } from 'ramda';
import React, { MouseEventHandler, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Plus } from '@/assets';
import { Button } from '@/atoms';
import { InfiniteScrollComponent } from '@/components';
import { ProjectCard, ProjectModal, TestsListHeader } from '@/containers';
import { usePermissions } from '@/hooks';
import { projectsEntitySelector } from '@/selectors';
import { useGetProjectsListQuery } from '@/services';
import { ButtonType } from '@/types';
import { isDefined } from '@/utils';

import styles from './styles.module.scss';

interface CreateProjectButtonProps {
  onCreateClick: () => void;
}

const CreateProjectButton: React.FC<CreateProjectButtonProps> = ({ onCreateClick }) => (
  <Button
    type={ButtonType.Primary}
    onClick={() => onCreateClick()}
    className={styles.addProjectBtn}
  >
    <Plus width={16} height={16} className={styles.plus} />
    <span>Добавить проект</span>
  </Button>
);

export const ProjectsPage = () => {
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingProjectId, setEditingProjectId] = useState<number>(null);

  const fetchData = async () => {
    await setOffset(page * 30);
    await setPage(inc);
  };

  const getListQueryParams = useMemo(() => {
    const params = {
      offset,
      order: '+id',
    } as Partial<GetManyProjectsQueryPrivate>;

    return params;
  }, [offset]);

  const { data } = useGetProjectsListQuery(
    { ...getListQueryParams, relations: ['logo'] },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Project, PermissionAction.Read),
    },
  );

  const projects: ProjectResponsePrivate<string>[] =
    useSelector(projectsEntitySelector.selectAll) || [];

  const onProjectEditClick = (id: number) => {
    setEditingProjectId(id);
    setShowEditModal(true);
  };

  const onProjectCreateClick = () => {
    setShowEditModal(true);
  };

  const onModalClose = () => {
    setShowEditModal(false);
    setEditingProjectId(null);
  };

  return (
    <div className={styles.projects}>
      <div className={styles.content}>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <TestsListHeader
              hideSearch
              button={<CreateProjectButton onCreateClick={onProjectCreateClick} />}
            />
          </Col>
        </Row>
        <div>
          <Row>
            <Typography.Title level={4} style={{ fontSize: '21px' }}>
              Проекты
            </Typography.Title>
          </Row>
          <InfiniteScrollComponent
            hasMore={isDefined(data) && data?.count > projects?.length}
            fetchData={fetchData}
            length={projects?.length || 0}
            offset={offset}
          >
            <div className={styles.grid}>
              {projects?.map((project) => (
                <ProjectCard project={project} onProjectEditClick={onProjectEditClick} />
              ))}
            </div>
          </InfiniteScrollComponent>
        </div>
        {showEditModal && <ProjectModal onClose={onModalClose} projectId={editingProjectId} />}
      </div>
    </div>
  );
};
