import { EmployeeResponsePrivate } from '@tests/types';
import { Col, Form, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Button, DropZone, FormItem, ProjectsFilter, Select } from '@/atoms';
import { DrawerWrapper } from '@/components';
import { DropType, DropZoneType } from '@/constants';
import { rolesEntitySelector } from '@/selectors';
import { useCreateEmployeeMutation, useUpdateEmployeeMutation } from '@/services';
import { ButtonType, FiltersItem } from '@/types';
import { STATUS_OPTIONS } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  employee: EmployeeResponsePrivate<string>;
  employeesRefetch: () => void;
  fetchRolesData: () => Promise<void>;
  hasMoreRoles: boolean;
  isRolesFetching: boolean;
  onClose: () => void;
};

export const EmployeeEditor: React.FC<Props> = ({
  employee,
  employeesRefetch,
  fetchRolesData,
  hasMoreRoles,
  isRolesFetching,
  onClose,
}) => {
  const [form] = useForm();
  const initialValues = employee
    ? {
        avatar: employee.avatar,
        name: employee.name,
        projects: employee?.projects.map(({ id, name }) => ({ label: name, value: id })),
        role: employee.role.id,
        status: employee.status,
      }
    : {};

  const [updateBlock] = useUpdateEmployeeMutation();
  const [createBlock] = useCreateEmployeeMutation();

  const roles = useSelector(rolesEntitySelector.selectAll) || [];
  const roleOptions = roles.map(({ id, name }) => ({ label: name, value: id }));

  const handleSaveEmployee = async () => {
    const data = form.getFieldsValue();
    if (employee) {
      await updateBlock({
        avatarId: data.avatar?.id || null,
        id: employee.id,
        name: data.name,
        projectIds: data.projects?.map((item: FiltersItem) => item.value),
        roleId: data.role,
        status: data.status,
      });
    } else {
      await createBlock({
        avatarId: data.avatar?.id || null,
        name: data.name,
        projectIds: data.projects?.map((item: FiltersItem) => item.value),
        roleId: data.role,
      });
    }
    employeesRefetch();
    onClose();
  };

  return (
    <DrawerWrapper
      title={employee ? 'Редактирование сотрудника' : 'Добавление сотрудника'}
      open
      handleClose={onClose}
      mobileDimension={768}
      desktopModal
      scrollActive
      height="calc(100% - 60px)"
      modalClassName={styles.editorWrapper}
      footer={
        <Row gutter={[10, 0]} className={styles.editBtnWrapper} justify="space-between">
          <Col xs={12}>
            <Button
              flex
              type={ButtonType.Secondary}
              className={classNames(styles.editBtn, styles.editCloseBtn)}
              onClick={onClose}
            >
              Отменить
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              flex
              className={classNames(styles.editBtn, styles.editSaveBtn)}
              onClick={() => form.submit()}
              type={ButtonType.Primary}
            >
              {employee ? 'Сохранить' : 'Добавить'}
            </Button>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        className={styles.form}
        onFinish={handleSaveEmployee}
        initialValues={initialValues}
      >
        <Form.Item noStyle name="avatar">
          <DropZone
            dropType={DropType.Image}
            type={DropZoneType.Avatar}
            rootClassName={styles.dropZoneRoot}
            className={styles.dropZone}
          />
        </Form.Item>
        <FormItem flex label="ФИО" name="name" className={styles.label}>
          <Input className={styles.input} placeholder="Введите имя сотрудника" />
        </FormItem>
        {employee && (
          <>
            <div className={styles.idWrapper}>
              <Col>
                <Typography.Text type="secondary">ID:</Typography.Text>
              </Col>
              <Col>
                <Typography.Text>{employee.id}</Typography.Text>
              </Col>
            </div>
            <FormItem
              flex
              label="Статус"
              name="status"
              className={styles.label}
              rootClassName={styles.formItemBlock}
            >
              <Select options={STATUS_OPTIONS} classname={styles.select} />
            </FormItem>
          </>
        )}
        <FormItem
          flex
          label="Роль"
          className={styles.label}
          name="role"
          rootClassName={styles.formItemBlock}
        >
          <Select
            hasMore={hasMoreRoles}
            fetchData={fetchRolesData}
            loading={isRolesFetching}
            options={roleOptions}
            classname={styles.select}
          />
        </FormItem>
        <FormItem
          flex
          label="Проекты"
          name="projects"
          className={styles.label}
          rootClassName={styles.formItemBlock}
        >
          <ProjectsFilter classname={styles.select} />
        </FormItem>
      </Form>
    </DrawerWrapper>
  );
};
