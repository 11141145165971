import { Button, Col, Form, Input, Row } from 'antd';
import classNames from 'classnames';
import { isNil } from 'ramda';
import { FC } from 'react';

import { DropZone, FormItem } from '@/atoms';
import { DrawerWrapper } from '@/components';
import { DropType, DropZoneType } from '@/constants';
import {
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useUpdateProjectByIdMutation,
} from '@/services';

import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  projectId?: number;
}

export const ProjectModal: FC<Props> = ({ onClose, projectId }) => {
  const { data } = useGetProjectByIdQuery(
    { id: projectId, relations: ['logo'] },
    { refetchOnMountOrArgChange: true, skip: !projectId },
  );

  const [updateProject] = useUpdateProjectByIdMutation();
  const [createProject] = useCreateProjectMutation();

  const [form] = Form.useForm();

  const { getFieldsValue } = form;

  const onFinish = async () => {
    const formValues = getFieldsValue({ filter: (meta) => meta.touched, strict: true });

    if (!isNil(projectId)) {
      await updateProject({
        id: projectId,
        ...formValues,
        adGroup: formValues.adGroup?.split(','),
      });
    } else {
      await createProject({
        ...formValues,
        adGroup: formValues.adGroup?.split(','),
      });
    }

    onClose();
  };

  return (
    <DrawerWrapper
      modalClassName={styles.modal}
      title={isNil(projectId) ? 'Добавление проекта' : 'Редактирование проекта'}
      open
      desktopModal
      handleClose={onClose}
      mobileDimension={768}
      height="calc(100% - 60px)"
      scrollActive
      footer={
        <Row gutter={[10, 0]} className={styles.btnWrapper} justify="space-between">
          <Col>
            <Button className={styles.cancelBtn} onClick={onClose}>
              Отменить
            </Button>
          </Col>
          <Col>
            <Button
              className={styles.saveBtn}
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              {isNil(projectId) ? 'Добавить' : 'Сохранить'}
            </Button>
          </Col>
        </Row>
      }
    >
      <div className={styles.body}>
        <Form
          name="project"
          form={form}
          layout="vertical"
          validateTrigger="onSubmit"
          onFinish={onFinish}
          className={styles.form}
        >
          <Row gutter={[0, 5]} className={classNames(styles.inputWrapper, styles.logoWrapper)}>
            <Col span={24}>
              <FormItem
                name="logoId"
                getValueFromEvent={(value) => value?.id ?? null}
                getValueProps={() => ({
                  value: data?.logo,
                })}
              >
                <DropZone
                  rootClassName={styles.dropZoneRoot}
                  className={styles.dropZone}
                  backgroundSize="contain"
                  placeholder="Добавьте логотип"
                  type={DropZoneType.Compact}
                  dropType={DropType.Image}
                  dropzonePreviewClassname={styles.dropZonePreview}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[0, 15]} className={styles.inputRowsWrapper}>
            <FormItem
              flex
              name="name"
              rules={[{ message: 'Введите название проекта', required: true }]}
              label="Название проекта"
              className={styles.formItemBlock}
            >
              <Input
                className={styles.input}
                placeholder={data?.name || 'Добавьте название проекта'}
              />
            </FormItem>
            <FormItem
              flex
              name="domain"
              label="Домен"
              rules={[{ message: 'Введите существующий домен', required: true }]}
              className={styles.formItemBlock}
            >
              <Input
                className={styles.input}
                placeholder={data?.domain || 'Добавьте домен проекта'}
              />
            </FormItem>
            <FormItem flex label="Счётчик метрики" name="ymId" className={styles.formItemBlock}>
              <Input
                className={styles.input}
                placeholder={data?.ymId ? data?.ymId.toString() : 'Добавьте номер счётчика'}
              />
            </FormItem>
            <FormItem flex name="ymToken" label="API токен" className={styles.formItemBlock}>
              <Input
                className={styles.input}
                placeholder={data?.ymToken ? data?.ymToken : 'Добавьте API токен'}
              />
            </FormItem>
          </Row>
          <FormItem
            flex
            name="adGroup"
            label="Группа АД"
            rules={[{ message: 'Введите группу АД', required: true }]}
            className={styles.formItemBlock}
          >
            <Input
              className={styles.input}
              placeholder={data?.adGroup ? data?.adGroup.toString() : 'Добавьте группу АД'}
            />
          </FormItem>
        </Form>
      </div>
    </DrawerWrapper>
  );
};
