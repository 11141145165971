import { PermissionResource } from '@tests/types';
import { Dispatch, SetStateAction } from 'react';

import { EntityFilter } from '@/atoms';
import { projectsEntitySelector } from '@/selectors';
import { useGetProjectsListQuery } from '@/services';

type FiltersItem = {
  label: string;
  value: number;
};

type FiltersProps = {
  classname?: string;
  onChange?: Dispatch<SetStateAction<FiltersItem[]>>;
  value?: FiltersItem[];
};

export const ProjectsFilter: React.FC<FiltersProps> = ({ classname, onChange, value }) => (
  <EntityFilter
    useEntityListQuery={useGetProjectsListQuery}
    entitySelector={projectsEntitySelector.selectAll}
    permissionResource={PermissionResource.Project}
    checkedItems={value}
    setCheckedItems={onChange}
    classname={classname}
  />
);
