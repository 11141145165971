import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Select, Spin, Tag } from 'antd';
import classNames from 'classnames';
import { ComponentProps, MouseEvent } from 'react';

import { ArrowDown, CloseTag } from '@/assets';
import { handlePopupScroll } from '@/utils';

import styles from './styles.module.scss';

export type FiltersItem = {
  label: string;
  value: number;
};

type TagItemProps = Parameters<NonNullable<ComponentProps<typeof Select>['tagRender']>>[0];

const tagRender = (
  itemProps: TagItemProps,
  checkedList: FiltersItem[],
  setCheckedList: (newCheckedList: FiltersItem[], name: string) => void,
  name?: string,
) => {
  const { label, value } = itemProps;
  const onPreventMouseDown = (event: MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDeleteOption = () => {
    setCheckedList(
      checkedList.filter((item) => item.value !== value),
      name,
    );
  };

  return (
    <Tag
      color="rgba(50, 99, 233, 0.15)"
      onMouseDown={onPreventMouseDown}
      onClose={handleDeleteOption}
      closable
      closeIcon={<CloseTag />}
      className={styles.tag}
    >
      {label}
    </Tag>
  );
};

const renderDropdown = (
  list: FiltersItem[],
  checkedList: FiltersItem[],
  setCheckedList: (newCheckedList: FiltersItem[], name: string) => void,
  name?: string,
  fetchData?: () => Promise<void>,
  hasMore?: boolean,
) => {
  const handleOptionChange = (option: FiltersItem) => {
    const { label, value } = option;
    const index = checkedList.findIndex((item) => item.value === value);
    if (index === -1) {
      setCheckedList([...checkedList, option], name);
    } else {
      setCheckedList(
        checkedList.filter(({ label: checkedName }) => checkedName !== label),
        name,
      );
    }
  };

  const getItemByValue = (value: number) => checkedList?.some((item) => item.value === value);

  return (
    <div
      className={styles.selectorOptions}
      onScroll={(e) => {
        if (
          hasMore &&
          e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
        ) {
          fetchData();
        }
      }}
    >
      {list.map((item) => (
        <div
          key={item.label}
          className={styles.selectorOption}
          onClick={(e) => {
            e.preventDefault();
            handleOptionChange(item);
          }}
        >
          <Checkbox
            className={classNames(
              styles.selectorCheckbox,
              getItemByValue(item.value) && styles.checkedSelectorCheckbox,
            )}
            checked={getItemByValue(item.value)}
            onChange={() => handleOptionChange(item)}
          >
            {item.label}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

type FiltersProps = {
  allowClear?: boolean;
  classname?: string;
  fetchData?: () => Promise<void>;
  hasMore?: boolean;
  loading: boolean;
  name?: string;
  onChange: (value: FiltersItem[], name?: string) => void;
  options: FiltersItem[];
  value: FiltersItem[];
};

export const MultiSelect: React.FC<FiltersProps> = ({
  allowClear = true,
  classname,
  fetchData,
  hasMore,
  loading,
  name,
  onChange,
  options,
  value,
}) => (
  <Select
    size="large"
    mode="multiple"
    allowClear={allowClear}
    onClear={() => onChange([], name)}
    notFoundContent={loading ? <Spin size="small" /> : null}
    options={options}
    dropdownRender={() => renderDropdown(options, value, onChange, name, fetchData, hasMore)}
    dropdownStyle={{ backgroundColor: 'var(--color-grey-lvl2)', padding: '12px 6px 12px 15px' }}
    value={value}
    tagRender={(customTagsProps) => tagRender(customTagsProps, value, onChange, name)}
    suffixIcon={<ArrowDown className={styles.icon} />}
    clearIcon={<CloseOutlined />}
    className={classNames(styles.selector, styles[classname])}
    placeholder="Выбрать"
    showSearch={false}
  />
);
