import { PlusOutlined } from '@ant-design/icons';
import {
  EmployeeResponsePrivate,
  GetManyEmployeesQueryPrivate,
  GetManyRolesQueryPrivate,
  PermissionAction,
  PermissionResource,
  RoleResponsePrivate,
} from '@tests/types';
import { Col, Row, Space, Tabs, TabsProps, Typography } from 'antd';
import classNames from 'classnames';
import { equals, inc, isEmpty } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Filters } from '@/assets';
import { Button } from '@/atoms';
import {
  DataList,
  EmployeeEditor,
  EmployeeFilters,
  RoleEditor,
  TestsListHeader,
} from '@/containers';
import { usePermissions, useWindowDimensions } from '@/hooks';
import { employeesEntitySelector, rolesEntitySelector } from '@/selectors';
import { useGetCurrentUserQuery, useGetEmployeesListQuery, useGetRolesListQuery } from '@/services';
import { ButtonType } from '@/types';
import { isDefined } from '@/utils';

import styles from './styles.module.scss';

export const EmployeesPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('employees');
  const [showFilter, setShowFilter] = useState(false);
  const [employeeEditorData, setEmployeeEditorData] = useState(null);
  const [showEmployeeEditorModal, setShowEmployeeEditorModal] = useState(false);
  const [showRoleEditor, setShowRoleEditor] = useState(null);
  const [employeePage, setEmployeePage] = useState(0);
  const [rolePage, setRolePage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [employeeFiltersParams, setEmployeeFiltersParams] = useState<
    Partial<GetManyEmployeesQueryPrivate>
  >({});

  const navigate = useNavigate();

  const pagePermitted = usePermissions(PermissionResource.Employee, PermissionAction.Read);

  const handleShowEmployeeEditor = (employee: EmployeeResponsePrivate<string>) => {
    setEmployeeEditorData(employee);
    setShowEmployeeEditorModal(true);
  };

  const handleShowRoleEditor = (role: RoleResponsePrivate<string>) => {
    setShowRoleEditor(role);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleUpdateEmployeeFiltersParams = (params: Partial<GetManyEmployeesQueryPrivate>) => {
    if (!equals(employeeFiltersParams, params)) {
      setEmployeePage(0);
      setEmployeeFiltersParams(params);
    }
  };

  const getEmployeeListQueryParams = useMemo(() => {
    let params = {
      offset: employeePage * 30,
      order: '+name',
      relations: ['role', 'avatar', 'projects.logo'],
    } as Partial<GetManyEmployeesQueryPrivate>;

    if (searchTerm) {
      params = {
        name: searchTerm,
        offset: 0,
        order: '+name',
        relations: ['role', 'avatar', 'projects.logo'],
      } as Partial<GetManyEmployeesQueryPrivate>;
    }

    if (!isEmpty(employeeFiltersParams)) {
      params = {
        ...params,
        ...employeeFiltersParams,
      };
    }

    return params;
  }, [employeePage, employeeFiltersParams, searchTerm]);

  const getRoleListQueryParams = useMemo(() => {
    const params = {
      offset: rolePage * 30,
      relations: ['permissions'],
    } as Partial<GetManyRolesQueryPrivate>;

    return params;
  }, [rolePage]);

  const {
    data: employeesData,
    isFetching: isEmployeesFetching,
    refetch: employeesRefetch,
  } = useGetEmployeesListQuery(getEmployeeListQueryParams, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: rolesData,
    isFetching: isRolesFetching,
    refetch: rolesRefetch,
  } = useGetRolesListQuery(getRoleListQueryParams, {
    refetchOnMountOrArgChange: true,
  });

  const employees = useSelector(employeesEntitySelector.selectAll) || [];
  const roles = useSelector(rolesEntitySelector.selectAll) || [];

  const { data: user } = useGetCurrentUserQuery();

  const windowWidth = useWindowDimensions().width;
  const mobileDimension = 768;
  const [isMobile, setIsMobile] = useState(windowWidth < mobileDimension);

  useEffect(() => {
    setIsMobile(windowWidth < mobileDimension);
  }, [windowWidth]);

  const fetchEmployeesData = async () => {
    setEmployeePage(inc);
  };

  const fetchRolesData = async () => {
    setRolePage(inc);
  };

  const tabs: TabsProps['items'] = [
    {
      children: (
        <DataList
          page={employeePage}
          isFetching={isEmployeesFetching}
          fetchData={fetchEmployeesData}
          listItemsData={employeesData}
          listItems={employees}
          listType="employees"
          handleShowEditor={handleShowEmployeeEditor}
        />
      ),
      key: 'employees',
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Пользователи</Col>
          <Col className={styles.tabBadge}>{employeesData?.count}</Col>
        </Row>
      ),
    },
    {
      children: (
        <DataList
          page={rolePage}
          isFetching={isRolesFetching}
          fetchData={fetchRolesData}
          listItemsData={rolesData}
          listItems={roles}
          listType="roles"
          handleShowEditor={handleShowRoleEditor}
        />
      ),
      key: 'roles',
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Роли</Col>
          <Col className={styles.tabBadge}>{rolesData?.count}</Col>
        </Row>
      ),
    },
  ];

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const additionalTabButtons = {
    right: activeTab === 'employees' && !isMobile && (
      <Space
        className={classNames(styles.showFilterBtn, showFilter && styles.activeShowFilterBtn)}
        onClick={handleShowFilter}
      >
        <Row align="middle">
          <Filters />
        </Row>
        <Row align="middle">
          {showFilter ? <Col span={24}>Скрыть фильтры</Col> : <Col span={24}>Все фильтры</Col>}
        </Row>
      </Space>
    ),
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <>
      <DefaultTabBar {...props} />
      {activeTab === 'employees' && isMobile && (
        <Row
          gutter={[5, 0]}
          align="middle"
          className={classNames(styles.showFilterBtn, showFilter && styles.activeShowFilterBtn)}
          onClick={handleShowFilter}
        >
          <Col>
            <Row>
              <Filters />
            </Row>
          </Col>
          <Col>
            {showFilter ? <Col span={24}>Скрыть фильтры</Col> : <Col span={24}>Все фильтры</Col>}
          </Col>
        </Row>
      )}
      {showFilter && activeTab === 'employees' && (
        <EmployeeFilters
          updateFilters={handleUpdateEmployeeFiltersParams}
          fetchRolesData={fetchRolesData}
          hasMoreRoles={isDefined(rolesData) && rolesData?.count > roles?.length}
          isRolesFetching={isRolesFetching}
          isEmployeesFetching={isEmployeesFetching}
          onShowFilters={handleShowFilter}
        />
      )}
    </>
  );

  useEffect(() => {
    if (user && !pagePermitted) {
      navigate('/no-access');
    }
  }, [navigate, pagePermitted, user]);

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <TestsListHeader
            hideSearch={activeTab === 'roles'}
            onSearch={handleSearch}
            button={
              <Button
                type={ButtonType.Primary}
                beforeIcon={<PlusOutlined />}
                onClick={() => setShowEmployeeEditorModal(true)}
              >
                <span className={styles.addEmployeeBtnText}>Добавить сотрудника</span>
              </Button>
            }
          />
        </Col>
      </Row>
      <Col className={styles.settingsWrapper}>
        <Row>
          <Typography.Title level={4} style={{ fontSize: '21px' }}>
            Сотрудники
          </Typography.Title>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              className={styles.tabs}
              destroyInactiveTabPane
              onChange={(e) => setActiveTab(e)}
              tabBarExtraContent={additionalTabButtons}
              tabBarGutter={20}
              defaultActiveKey="users"
              items={tabs}
              renderTabBar={renderTabBar}
            />
          </Col>
        </Row>
      </Col>
      {showEmployeeEditorModal && (
        <EmployeeEditor
          employee={employeeEditorData}
          onClose={() => {
            setShowEmployeeEditorModal(false);
            setEmployeeEditorData(null);
          }}
          employeesRefetch={employeesRefetch}
          fetchRolesData={fetchRolesData}
          isRolesFetching={isRolesFetching}
          hasMoreRoles={rolesData?.count > roles?.length}
        />
      )}
      {showRoleEditor && (
        <RoleEditor
          role={showRoleEditor}
          onClose={() => setShowRoleEditor(null)}
          rolesRefetch={rolesRefetch}
        />
      )}
    </>
  );
};
