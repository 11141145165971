import classNames from 'classnames';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { ButtonSize, ButtonType } from '@/types';

import styles from './styles.module.scss';

interface Props {
  afterIcon?: ReactNode;
  beforeIcon?: ReactNode;
  className?: string;
  disabled?: boolean;
  flex?: boolean;
  href?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  rounded?: boolean;
  size?: ButtonSize;
  square?: boolean;
  target?: string;
  type?: ButtonType;
}

export const Button: FC<PropsWithChildren<Props>> = ({
  afterIcon,
  beforeIcon,
  children,
  className,
  disabled,
  flex,
  onClick,
  htmlType = 'button',
  rounded,
  size = ButtonSize.Regular,
  square,
  href,
  target,
  type = ButtonType.Text,
}) => {
  if (href) {
    return (
      <a
        href={href}
        target={target}
        className={classNames(
          styles.button,
          styles[type],
          styles[size],
          className,
          (!children || square) && styles.square,
          rounded && styles.rounded,
          flex && styles.flex,
          beforeIcon && afterIcon && styles.doubleIcon,
        )}
        onClick={onClick}
      >
        {beforeIcon && (
          <div className={classNames(styles.icon, styles.beforeIcon)}>{beforeIcon}</div>
        )}
        {children}
        {afterIcon && <div className={classNames(styles.icon, styles.afterIcon)}>{afterIcon}</div>}
      </a>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={htmlType}
      disabled={disabled}
      className={classNames(
        styles.button,
        styles[type],
        styles[size],
        className,
        (!children || square) && styles.square,
        rounded && styles.rounded,
        flex && styles.flex,
        beforeIcon && afterIcon && styles.doubleIcon,
      )}
      onClick={onClick}
    >
      {beforeIcon && <div className={classNames(styles.icon, styles.beforeIcon)}>{beforeIcon}</div>}
      {children}
      {afterIcon && <div className={classNames(styles.icon, styles.afterIcon)}>{afterIcon}</div>}
    </button>
  );
};
